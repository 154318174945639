@import "@blueprintjs/colors/lib/scss/colors";
@import '@blueprintjs/core/src/common/variables';

$ns: bp4;

$pt-popover-box-shadow: 0 0 0 1px rgba(16, 22, 26, 0.02),
  0 2px 4px rgba(16, 22, 26, 0.1), 0 8px 24px rgba(16, 22, 26, 0.1);

$blue1: #0069ff;
$blue2: #0052ff;
$blue3: rgb(0, 82, 204);
$pt-link-color: $blue3;
$pt-intent-primary: $blue1;
$menu-item-color-hover: $light-gray4;
$menu-item-color-active: $light-gray3;

$breadcrumbs-collapsed-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#6B8193' enable-background='new 0 0 16 16' xml:space='preserve'><g><circle cx='2' cy='8.03' r='2'/><circle cx='14' cy='8.03' r='2'/><circle cx='8' cy='8.03' r='2'/></g></svg>");

$sidebar-zindex: 16;
$pt-font-family: 'Noto Sans', -apple-system, BlinkMacSystemFont,
  Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue,
  Icons16, sans-serif;

$button-box-shadow: 0 0 0 !default;
$button-box-shadow-active: 0 0 0 !default;
$button-intent-box-shadow: 0 0 0 !default;
$button-intent-box-shadow-active: 0 0 0 !default;

$button-background-color-disabled: #e9ecef !default;
$button-background-color: #e6effb !default;
$button-background-color-hover: #cfdcee !default;

$sidebar-background: #01115e;
$sidebar-text-color: #fff;
$sidebar-width: 100%;
$sidebar-menu-item-color: rgb(255, 255, 255);
$sidebar-menu-item-color-active: rgb(255, 255, 255);
$sidebar-popover-submenu-bg: rgb(1, 20, 62);
$sidebar-menu-label-color: rgba(255, 255, 255, 0.5);
$sidebar-submenu-item-color: rgba(255, 255, 255, 0.85);
$sidebar-submenu-item-hover-color: rgb(255, 255, 255);
$sidebar-logo-opacity: 0.5;
$sidebar-submenu-item-bg-color: rgba(255, 255, 255, 0.2);

$form-check-input-checked-color: #fff;
$form-check-input-checked-bg-color: $blue1;
$form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' enable-background='new 0 0 16 16' xml:space='preserve'><g id='small_tick_1_'><g><path fill='#{$form-check-input-checked-color}' fill-rule='evenodd' clip-rule='evenodd' d='M12,5c-0.28,0-0.53,0.11-0.71,0.29L7,9.59L4.71,7.29C4.53,7.11,4.28,7,4,7C3.45,7,3,7.45,3,8c0,0.28,0.11,0.53,0.29,0.71l3,3C6.47,11.89,6.72,12,7,12s0.53-0.11,0.71-0.29l5-5C12.89,6.53,13,6.28,13,6C13,5.45,12.55,5,12,5z'/></g></g></svg>") !default;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 16 16' enable-background='new 0 0 16 16' xml:space='preserve'><g id='small_tick_1_'><g><path fill='#{$form-check-input-checked-color}' fill-rule='evenodd' clip-rule='evenodd' d='M11,7H5C4.45,7,4,7.45,4,8c0,0.55,0.45,1,1,1h6c0.55,0,1-0.45,1-1C12,7.45,11.55,7,11,7z'/></g></g></svg>") !default;

// z-indexs
$zindex-dashboard-splash-screen: 39;
$zindex-toast: 40;
